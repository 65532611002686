import { createApp } from 'vue'
import App from './App.vue'
import apiAxios from '../../config/request.js'
import axios from "axios";
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import '/src/assets/css/comment.css';


const app = createApp(App);

app.directive('focus', {
	// 当被绑定的元素挂载到 DOM 中时……
	mounted(el) {
		// 聚焦元素
		el.focus();
		// 选中内容
		el.select();
	},

})
app.config.globalProperties.$apiRequest = apiAxios; 
//将实例挂载至节点
app.use(Antd).mount('#beatuy__root')
