<template>
		<a-modal wrapClassName="beautifyModal" :mask="true" :destroyOnClose="true" :footer="null"  cancelText="取消" okText="确认" :centered="true" :visible="beautyshow"  @cancel="beautifyCancle" title="二维码美化">
			<beautifyMod :beautyStepInit="beautyStepInit" :qrcodeContentData="qrcodeContentData" @complete="completeFunc" @change="changeFunc"></beautifyMod>
		</a-modal>
		
		<!-- 下载其他格式 -->
		<a-modal width="1000" wrapClassName="downloadImgModal" :mask="true" :closable="false" :destroyOnClose="true" :footer="null" :centered="true" :visible="downloadOrtherExtData.visible"  title="">
			<downloadImg @closedownloadimg="downloadOrtherExtData.closeDownloadOrtherExt" :qrcodeInfo="downloadOrtherExtData.qrcodeInfo"></downloadImg>
		</a-modal>
		<div id="qrcode__beautify__popup__func__jxi8129xhaw" @click="openbeautify"></div>
		<div id="qrcode__beautify__download__popup__func__jxi8129xhaw" @click="downloadOrtherExtData.openDownloadOrtherExt"></div>
</template>

<script>
	import downloadImg from '../../components/downloadImg';
	import beautifyMod from '../../components/beautifyMod';
	export default {
		components: {
			beautifyMod,downloadImg
		},
		data(){
			return{
				beautyshow:false,
				beautyStepInit:1,
				qrcodeContentData:{
					qrcode_type:"text",/*二维码类型*/
					id_key:"",/*二维码id_key*/
					content:"",/*二维码内容*/
					config:null,/*二维码参数*/
					moban_id:0,/*模板id*/
				},
				downloadOrtherExtData:{
					visible:false,
					qrcodeInfo:{
						config:null,
						content:"http://www.wwei.cn",
						id_key:"",
						base64:null,
						name:"",
					},
					openDownloadOrtherExt:()=>{
						if(typeof qrcodeBeautifyConfigData8jshxu !='undefined' && qrcodeBeautifyConfigData8jshxu){
							this.qrcodeContentData = JSON.parse(JSON.stringify(qrcodeBeautifyConfigData8jshxu))
						}
						this.downloadOrtherExtData.qrcodeInfo.config = JSON.parse(JSON.stringify(this.qrcodeContentData.config));
						this.downloadOrtherExtData.qrcodeInfo.content = this.qrcodeContentData.content;
						this.downloadOrtherExtData.visible = true;
					},
					closeDownloadOrtherExt:()=>{
						this.downloadOrtherExtData.visible = false;
					},
				},
			}
		},
		methods:{
			openbeautify(){
				if(typeof qrcodeBeautifyConfigData8jshxu !='undefined' && qrcodeBeautifyConfigData8jshxu){
					this.qrcodeContentData = JSON.parse(JSON.stringify(qrcodeBeautifyConfigData8jshxu))
				}
				this.beautyshow=true
			},
			beautifyCancle(){
				this.beautyshow=false;
				this.callbackFunc("close")
			},
			completeFunc(e){
				this.beautyshow=false;
				this.callbackFunc("complete",e)
			},
			changeFunc(e){
				this.callbackFunc("change",e)
			},
			callbackFunc(emit,info){
				if(typeof qrcodeBeautifyPopupFunc !='undefined' && qrcodeBeautifyPopupFunc && typeof qrcodeBeautifyPopupFunc.beautifyChange =='function'){
					qrcodeBeautifyPopupFunc.beautifyChange(emit,info)
				}
			},
		},
		mounted(){
		}
	}
	
</script>

<style>
	.beautifyModal .ant-modal{
		width: 1280px !important;
	}
	@media (max-width: 1282px){
		.beautifyModal .ant-modal{
			width: 1100px !important;
		}
	}
	@media (max-width: 1110px){
		.beautifyModal .ant-modal{
			width: 1000px !important;
		}
	}
	.beautifyModal .ant-modal-body{
		padding: 0 !important;
	}
</style>
